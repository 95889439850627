import { timeout } from '@/assets/js/utils';
import routesStore from '@/store/routes';
export let router;
export default (ctx) => {
    console.log("plugin router");
    const app = ctx.app;
    router = app.router;
    router.onError(function (e) {
        console.log("router, onError", e);
    });
    const route = ctx.route;
    routesStore.updateRoute(route);
    routesStore.router = router;
};
export async function routerReplace(path, ...args) {
    await timeout(0);
    return router.replace(path, ...args);
}
export async function routerPush(path, ...args) {
    await timeout(1);
    if (router) {
        return router.push(path, ...args);
    }
    await timeout(1000);
    return routerPush(path, ...args);
}
