export default {
    getItem(key) {
        let item = null;
        const NativeStorage = "undefined" != typeof window ? window.NativeStorage : null;
        if (NativeStorage) {
            try {
                item = NativeStorage.getItem(key);
            }
            catch (e) {
            }
        }
        if (item) {
            return item;
        }
        try {
            return localStorage.getItem(key);
        }
        catch (e) {
        }
        return null;
    },
    setItem(key, value) {
        const NativeStorage = "undefined" != typeof window ? window.NativeStorage : null;
        if (NativeStorage) {
            try {
                localStorage.setItem(key, value);
            }
            catch (e) {
            }
        }
        try {
            localStorage.setItem(key, value);
        }
        catch (e) {
        }
    },
    removeItem(key) {
        const NativeStorage = "undefined" != typeof window ? window.NativeStorage : null;
        if (NativeStorage) {
            try {
                localStorage.removeItem(key);
            }
            catch (e) {
            }
        }
        try {
            localStorage.removeItem(key);
        }
        catch (e) {
        }
    }
};
