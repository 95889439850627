import _window from "@/libraries/window";
import Vue from "@/libraries/vue";
import { moduleDebug } from "@/assets/js/debug";
import { DOMContentLoaded, windowLoad } from '@/assets/js/utils';
const indexStore = new Vue({
    name: "Store",
    data() {
        const data = {
            location: {
                city: null,
                district: null,
            },
            drawerDialog: {
                model: false
            },
        };
        return data;
    },
    asyncComputed: {
        async DOMContentLoaded() {
            await DOMContentLoaded();
            console.log("DOMContentLoaded!!!");
            return true;
        },
        async windowLoad() {
            await windowLoad();
            console.log("windowLoad!!!");
            return true;
        }
    },
    methods: {
        getAsyncData() {
            var _a;
            const nuxtData = (_a = _window.__NUXT__) === null || _a === void 0 ? void 0 : _a.data;
            const asyncData = (nuxtData && nuxtData.length ? nuxtData[0] : null);
            return asyncData || {};
        }
    }
});
export default indexStore;
moduleDebug(indexStore);
