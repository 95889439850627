import _window from "@/libraries/window";
import Vue from "@/libraries/vue";
import axios from "axios";
import { API_URL } from "@/assets/js/utils";
import keywords from "@/assets/js/keywords";
import store from "@/store";
import routesStore from "@/store/routes";
export default Vue.extend({
    name: "DefaultLayout",
    data() {
        return {
            searchModel: "",
            searchFocus: false,
        };
    },
    computed: {
        page: () => routesStore.page,
        drawerDialog: () => store.drawerDialog,
        searchActive() {
            const searchFocus = this.searchFocus;
            const searchModel = this.searchModel;
            return searchFocus || !!searchModel;
        },
        title() {
            const location = _window.location;
            return location ? location.host : "";
        },
    },
    methods: {
        search() {
            const searchModel = this.searchModel;
            if (!searchModel)
                return;
            const wordsArr = searchModel.split(" ");
            const filered = keywords(wordsArr);
            const words = filered.join(",");
            const req = "/searches?search=" + searchModel + "&words=" + words;
            axios.post(API_URL + req);
        },
    },
});
