import Vue from "vue";
const routesStore = new Vue({
    name: "RoutesStore",
    data() {
        return {
            router: {},
            route: {},
            fullPath: "",
            name: "",
            page: "",
            query: {}
        };
    },
    computed: {
        homePage() {
            const page = this.page;
            return !page || "index" == page;
        }
    },
    watch: {
        "router.currentRoute"(route) {
            console.log("router.currentRoute watch");
            this.updateRoute(route);
            this.route = route;
        },
        route: {
            deep: true,
            handler(route) {
                console.log("route watch");
                this.updateRoute(route);
            }
        }
    },
    methods: {
        updateRoute(route) {
            if (!route) {
                console.warn("!route");
                return;
            }
            if (this.fullPath != route.fullPath) {
                this.fullPath = route.fullPath;
            }
            console.log("updateRoute", this.name, "->", route.name);
            if (this.name != route.name) {
                this.name = route.name || "";
            }
            if (route.name) {
                const pageArr = route.name.split("_")[0].split("-");
                let page = pageArr.length > 1 ? pageArr[1] : "index";
                if (this.page != page) {
                    console.log("this.page = ", page);
                    this.page = page;
                }
            }
            const query = route.query;
            if (this.query != query) {
                this.query = query;
            }
        }
    }
});
export default routesStore;
import("@/assets/js/debug").then(function (imported) {
    imported.moduleDebug(routesStore, true);
});
