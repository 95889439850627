import _window from "@/libraries/window";
import { windowLoad, requestIdleCallback, appendLink, visibilityUpdate, reload } from "@/assets/js/utils";
export default async function (ctx) {
    console.log("plugins/index");
    const location = _window.location;
    if (location && "www.censurado.net" === location.host) {
        location.replace(location.href.replace(location.host, "censurado.net"));
        return;
    }
    (async function () {
        await windowLoad();
        await requestIdleCallback();
        import('@fortawesome/fontawesome-free/css/all.css');
        appendLink("https://unpkg.com/flag-icon-css@3.4.5/css/flag-icon.min.css");
        if (typeof document != 'undefined') {
            document.body.addEventListener('click', function (event) {
                const target = event.target;
                if (target && target.matches && target.matches('.v-overlay--active')) {
                    console.log('overlay click');
                    if (!document.querySelector('.v-dialog--active')) {
                        const overlayActive = document.querySelector('.v-overlay--active');
                        if (overlayActive) {
                            overlayActive.style.zIndex = '-99';
                        }
                    }
                }
            });
        }
    })();
}
const originalConsoleLog = console.log;
if (!process.client) {
    console.log = notObjectsLog;
    console.warn = notObjectsLog;
    console.error = notObjectsLog;
}
function notObjectsLog() {
    const args = [];
    for (const argument of arguments) {
        if ("string" != typeof argument && "number" != typeof argument && "boolean" != typeof argument) {
            continue;
        }
        args.push(argument);
    }
    originalConsoleLog(...args);
}
const start = (new Date).getTime();
visibilityUpdate((visible) => {
    if (!visible)
        return;
    const now = (new Date).getTime();
    if (now - start > 57600000) {
        reload(false);
    }
});
