export default function (wordsArr) {
    const words = wordsArr.map(w => {
        return w.toLowerCase()
            .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            .replace(/[^a-zA-Z0-9]/g, "")
            .replace(/s$/, "");
    });
    return words.filter((word) => {
        return word.length > 2
            && !determinants.includes(word)
            && !prepositions.includes(word)
            && !adverbs.includes(word)
            && !ignore.includes(word);
    });
}
const determinants = [
    "el",
    "la",
    "lo",
    "los",
    "las",
    "mi",
    "tu",
    "su",
    "mis",
    "tus",
    "sus",
    "nuestro",
    "vuestro",
    "nuestra",
    "vuestra",
    "nuestros",
    "vuestros",
    "nuestras",
    "vuestras",
    "este",
    "ese",
    "aquel",
    "esta",
    "esa",
    "aquella",
    "estos",
    "esos",
    "aquellos",
    "estas",
    "esas",
    "aquellas",
    "bastante",
    "mucho",
    "poco",
    "algun",
    "ningun",
    "cierto",
    "mucha",
    "poca",
    "alguna",
    "ninguna",
    "cierta",
    "bastantes",
    "muchos",
    "pocos",
    "algunos",
    "ningunos",
    "ciertos",
    "muchas",
    "pocas",
    "algunas",
    "ningunas",
    "ciertas",
    "que",
    "cual",
    "cuales",
    "quien",
    "quienes",
    "cuan",
    "cuando",
    "como",
    "cuanto",
    "cuantos",
    "cuanta",
    "cuantas",
    "donde",
    "adonde"
];
const prepositions = [
    "a",
    "ante",
    "bajo",
    "cabe",
    "con",
    "contra",
    "de",
    "desde",
    "en",
    "entre",
    "hacia",
    "hasta",
    "para",
    "por",
    "segun",
    "sin",
    "so",
    "sobre",
];
const adverbs = [
    "ayer",
    "hoy",
    "manana",
    "siempre",
    "nunca",
    "luego",
    "ya",
    "ahora",
    "frecuentemente",
    "antes",
    "aqui",
    "ahi",
    "alli",
    "alla",
    "aca",
    "cerca",
    "lejos",
    "arriba",
    "abajo",
    "delante",
    "detras",
    "enfrente",
    "encima",
    "debajo",
    "donde",
    "antes",
    "asi",
    "bien",
    "mal",
    "cuidadosamente",
    "mejor",
    "peor",
    "como",
    "mucho",
    "poco",
    "mas",
    "menos",
    "bastante",
    "nada",
    "cuanto",
    "si",
    "claro",
    "bueno",
    "obviamente",
    "tambien",
    "no",
    "tampoco",
    "nada",
    "apenas",
    "jamas",
    "nunca",
    "quiza",
    "probablemente",
    "seguramente",
    "acaso",
    "demas",
    "inclusive",
    "aun"
];
const ignore = [
    "cada",
    "vez",
    "veces",
    "favor",
    "usar",
    "menos",
    "hora",
    "horas",
    "diaria",
    "diarias",
    "puede",
    "provocarle"
];
