import findKey from 'lodash/findKey';
import isEqual from 'lodash/isEqual';
import _window from "@/libraries/window";
import Vue from "@/libraries/vue";
import LocalStorage from "@/libraries/LocalStorage";
import { windowLoad, timeout, isProduction } from "@/assets/js/utils";
if (!process.client && "development" != process.env.NODE_ENV) {
    console.log = doNothing;
    console.warn = doNothing;
}
const debugStore = new Vue({
    name: "Debug",
    computed: {
        alpha() {
            return isAlphaLocation();
        },
        beta() {
            return isBetaLocation();
        },
        debug() {
            const alpha = this.alpha;
            if (alpha)
                return true;
            if (!isProduction())
                return true;
            return false;
        },
        hideLogs() {
            const alpha = this.alpha;
            const beta = this.beta;
            if (!process.client)
                return false;
            if (alpha || beta)
                return false;
            if (!isProduction())
                return false;
            return true;
        }
    },
    watch: {
        hideLogs: {
            immediate: true,
            handler(hide) {
            }
        }
    }
});
export default debugStore;
export async function moduleDebug(module, notWatch) {
    if (!process.client) {
        return;
    }
    await timeout(1);
    const debug = debugStore.debug;
    if (debug) {
        if (!notWatch) {
            module.$watch("$data", {
                handler: function (data, oldVal) {
                    try {
                        if (!this._priorState) {
                            try {
                                this._priorState = this.$options.data();
                            }
                            catch (e) {
                                this._priorState = { ...data };
                                return;
                            }
                        }
                        let self = this;
                        let changedProp = findKey(data, (val, key) => {
                            return !isEqual(val, self._priorState[key]);
                        });
                        if (changedProp) {
                            console.log("%cdata", "color:#009954", changedProp, this._priorState[changedProp], "->", data[changedProp]);
                        }
                        this._priorState = { ...data };
                    }
                    catch (e) {
                        console.log("debug watch", e);
                    }
                },
                deep: true
            });
        }
        const name = module.$options.name;
        const computed = module._computedWatchers;
        for (const key in computed) {
            (function () {
                const original = computed[key].getter;
                computed[key].getter = function () {
                    console.log("%ccomputed", "color:#009954", name, key);
                    return original.apply(this, arguments);
                };
            })();
        }
        const document = _window.document;
        document && windowLoad().then(function () {
            module.$mount(document.body.appendChild(document.createElement("div")));
        });
    }
}
moduleDebug(debugStore);
function isAlphaLocation() {
    if (LocalStorage.getItem("alpha")) {
        return true;
    }
    const location = _window.location;
    if (location && location.search.includes("alpha=1")) {
        return true;
    }
    return false;
}
function isBetaLocation() {
    if (LocalStorage.getItem("beta")) {
        return true;
    }
    const location = _window.location;
    if (!location) {
        return false;
    }
    if (location.search.includes("beta=1")) {
        return true;
    }
    return false;
}
function doNothing() {
}
