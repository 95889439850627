// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/fonts/top-secret.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face{font-family:\"top-secret\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}html{overflow-y:inherit!important;width:100vw;height:100vh;scroll-behavior:smooth;background-size:200px;background-position:50%}.v-main{min-height:100vh}#header{margin-bottom:20px}#body{color:#323232}#body .button:hover,#body button:hover{background:rgba(0,0,0,.05)}.v-application{background:none!important}input:-webkit-autofill,input:-webkit-autofill:active,input:-webkit-autofill:focus,input:-webkit-autofill:hover{-webkit-transition:background-color 5000s ease-in-out 0s;transition:background-color 5000s ease-in-out 0s}*{-webkit-overflow-scrolling:touch}@media (min-width:600px){::-webkit-scrollbar{width:8px;height:8px;background-color:transparent;border-radius:100px;-webkit-border-radius:100px}::-webkit-scrollbar:hover{background-color:rgba(0,0,0,.09)}::-webkit-scrollbar-thumb{background:rgba(0,0,0,.3);border-radius:100px;-webkit-border-radius:100px}::-webkit-scrollbar-thumb:active{background:rgba(0,0,0,.5);border-radius:100px;-webkit-border-radius:100px}}img{max-width:100%}", ""]);
// Exports
module.exports = exports;
