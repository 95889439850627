const originalConsoleLog = console.log;
function onlyStringLog(...args) {
    const stringArgs = [];
    for (const arg of args) {
        const type = typeof arg;
        if (type != "string" && type != "number" && type != "boolean") {
            stringArgs.push(type);
            continue;
        }
        stringArgs.push(arg);
    }
    return originalConsoleLog(...stringArgs);
}
if (!process.client) {
    console.log("polyfill !process.client onlyStringLog");
    console.log = onlyStringLog;
    console.warn = onlyStringLog;
    console.error = onlyStringLog;
}
const _window = "undefined" != typeof window ? window : {};
export default _window;
